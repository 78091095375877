<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-button
        class="btn btn-gradient-primary mb-1 mr-50 col-md-2"
        @click="backorderprint()"
      >
        {{ $t('returnToList') }}
      </b-button>
      <b-button
        v-if="items.prints"
        variant="danger"
        class="mb-1 ml-25 mr-50 col-md-2"
        @click="saveprint(false)"
      >
        <i class="fa fa-save" />
        {{ $t('recordNotprinted') }}
      </b-button>
      <b-button
        v-else
        variant="warning"
        class="mb-1 ml-25 mr-50 col-md-2"
        @click="saveprint(true)"
      >
        <i class="fa fa-save" />
        {{ $t('recordPrinted') }}
      </b-button>
      <b-button
        variant="secondary"
        class="mb-1 ml-25 mr-50 col-md-2"
        @click="printpdf()"
      >
        <i class="fa fa-print" />
        {{ $t('print') }}
      </b-button>
      <b-row>
        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
              <h3 class="mb-0">
                {{ $t('orderLists') }} {{ ID }}

              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col>
                  <div class="align-items-center text-left mb-n2">
                    <b-form-group
                      label-cols="6"
                      label-cols-lg="6"
                      label-cols-md="6"
                    >
                      <template v-slot:label>
                        {{ $t('NumberOrderLists') }} :
                      </template>
                      <p class="mt-50">
                        {{ ID }}
                      </p>

                    </b-form-group>
                  </div>
                  <div class="align-items-center text-left mb-n2">
                    <b-form-group
                      label-cols="6"
                      label-cols-lg="6"
                      label-cols-md="6"
                    >
                      <template v-slot:label>
                        {{ $t('printStatus') }} :
                      </template>
                      <p
                        v-if="items.prints"
                        class="mt-50 badge badge-light-success col-md-3"
                      >
                        {{ $t('printed') }}
                      </p>
                      <p
                        v-else
                        class="mt-50 badge badge-light-danger col-md-3"
                      >
                        {{ $t('notPrint') }}
                      </p>

                    </b-form-group>
                  </div>
                  <div class="align-items-center text-left mb-n2">
                    <b-form-group
                      label-cols="6"
                      label-cols-lg="6"
                      label-cols-md="6"
                    >
                      <template v-slot:label>
                        Reterence :
                      </template>
                      <router-link :to="{ name: 'admin-user-edit', params: { id: 1 } }">
                        <p class="mt-50">
                          ORD-{{ items.orderId_text }}
                        </p>
                      </router-link>
                    </b-form-group>
                  </div>
                  <div class="align-items-center text-left mb-n2">
                    <b-form-group
                      label-cols="6"
                      label-cols-lg="6"
                      label-cols-md="6"
                    >
                      <template v-slot:label>
                        {{ $t('customerCode') }} :
                      </template>
                      <router-link :to="{ name: 'admin-user-edit', params: { id: 1 } }">
                        <p class="mt-50">
                          {{ items.username }}
                        </p>
                      </router-link>

                    </b-form-group>
                  </div>
                  <div class="align-items-center text-left mb-n2">
                    <b-form-group
                      label-cols="6"
                      label-cols-lg="6"
                      label-cols-md="6"
                    >
                      <template v-slot:label>
                        {{ $t('deliveryType') }} :
                      </template>
                      <p
                        v-if="items.delivery_type == 1"
                        class="mt-50 badge badge-light-primary col-md-3"
                      >
                        {{ $t('truck') }}
                      </p>
                      <p
                        v-if="items.delivery_type == 2"
                        class="mt-50 badge badge-light-danger col-md-3"
                      >
                        {{ $t('ship') }}
                      </p>

                    </b-form-group>
                  </div>
                  <div class="align-items-center text-left mb-n2">
                    <b-form-group
                      label-cols="6"
                      label-cols-lg="6"
                      label-cols-md="6"
                    >
                      <template v-slot:label>
                        {{ $t('status') }} :
                      </template>
                      <p class="mt-50 badge badge-light-success col-md-3">
                        {{ $t('orderSuccess') }}
                      </p>

                    </b-form-group>
                  </div>
                  <div class="align-items-center text-left mb-n2">
                    <b-form-group
                      label-cols="6"
                      label-cols-lg="6"
                      label-cols-md="6"
                    >
                      <template v-slot:label>
                        {{ $t('orderRate') }} :
                      </template>
                      <p class="mt-50">
                        "1 = {{ items.rate_yuan }} ฿
                      </p>

                    </b-form-group>
                  </div>
                  <div class="align-items-center text-left mb-n2">
                    <b-form-group
                      label-cols="6"
                      label-cols-lg="6"
                      label-cols-md="6"
                    >
                      <template v-slot:label>
                        {{ $t('mnemonic') }} :
                      </template>
                      <p class="mt-50">
                        {{ items.note ? items.note: '-' }}
                      </p>

                    </b-form-group>
                  </div>
                  <div class="align-items-center text-left mb-n2">
                    <b-form-group
                      label-cols="6"
                      label-cols-lg="6"
                      label-cols-md="6"
                    >
                      <template v-slot:label>
                        {{ $t('mnemonicPhraseAdmin') }} :
                      </template>
                      <p class="mt-50">
                        {{ items.remark_admin ? items.remark_admin: '-' }}
                      </p>

                    </b-form-group>
                  </div>
                  <div class="align-items-center text-left mb-n2">
                    <b-form-group
                      label-cols="6"
                      label-cols-lg="6"
                      label-cols-md="6"
                    >
                      <template v-slot:label>
                        {{ $t('lastEditedBy') }} :
                      </template>
                      <p class="mt-50">
                        {{ items.edit_by }}
                      </p>

                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('listProducts') }}
              </h3>
            </div>
            <div
              v-for="(item,index) in items.shops"
              :key="index"
              class="p-2 table-container"
            >

              <table class="table-blue w-100">
                <colgroup>
                  <col style="width: 30%;">
                  <col style="width: 15%;">
                  <col style="width: 15%;">
                  <col style="width: 15%;">
                  <col style="width: 15%;">
                  <col style="width: 10%;">
                </colgroup>
                <thead>
                  <tr>
                    <th
                      scope="col"
                      colspan="2"
                    >
                      <div class="d-flex align-items-center">
                        <span class="mr-2">{{ index+1 }}. {{ item.storename }}</span>
                        <b-form-checkbox
                          :checked="item.packbox"
                          class="custom-control-primary"
                          name="check-button"
                          disabled
                          switch
                        />
                        <b-card-text class="mb-0 mr-2">
                          {{ $t('woodPacking') }}
                        </b-card-text>

                        <b-form-checkbox
                          :checked="item.checkproduct"
                          class="custom-control-primary"
                          name="check-button"
                          disabled
                          switch
                        />
                        <b-card-text class="mb-0 mr-2">
                          {{ $t('checkOrder') }}
                        </b-card-text>

                        <span>{{ $t('type') }} {{ item.product_type ? item.product_type.pro_nam: '-' }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      {{ $t('quantity') }}
                    </th>
                    <th scope="col">
                      {{ $t('yuanPrice') }}
                    </th>
                    <th scope="col">
                      {{ $t('yuanTotal') }}
                    </th>
                    <th scope="col">
                      {{ $t('ThaiPrice') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(items_product, index_product) in item.products"
                    :key="index_product"
                    class="anan-order-row"
                  >
                    <td colspan="2">
                      <div class="d-flex align-items-center justify-content-between p-1">
                        <div class="d-flex align-items-center">
                          <span>{{ 0+1 }}.{{ index_product + 1 }}</span>
                          <img
                            :src="items_product.color_img ? items_product.color_img : items_product.picture"
                            :alt="items_product.picture"
                            height="50px"
                            class="ml-2 rounded mr5 cursor-pointer"
                            @click="showPicture(items_product.color_img ? items_product.color_img : items_product.picture)"
                          >

                          <div>
                            <h5 class="mb-0 JB57cn2">
                              {{ $t('firstName') }} :{{ items_product.name }}
                            </h5>
                            <p
                              v-if="items_product.url"
                              class="mb-0"
                            >
                              <a
                                :href="`${items_product.url}`"
                                target="_blank"
                              >
                                {{ $t('link') }} : {{ items_product.url ? items_product.url: '-' }}
                              </a>
                            </p>
                            <p
                              v-if="items_product.color_title"
                              class="mb-0"
                            >
                              {{ $t('color') }} : {{ items_product.color_title ? items_product.color_title: '-' }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {{ Commas(items_product.quantity ? items_product.quantity: 0) }}
                    </td>
                    <td>
                      {{ items_product.price ? Commas(Number(items_product.price)) : '0.00' }} ¥
                    </td>
                    <td>
                      {{ Commas((items_product.quantity * Number(items_product.price))) }} ¥
                    </td>
                    <td>
                      {{ Commas(((items_product.quantity * Number(items_product.price))* items.rate_yuan )) }} ฿
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="card-info mt-1 ">
                <!-- {{ item }} -->
                <div class="row">
                  <div class="col-md-8" />

                  <div class="col-md-4">
                    <div>
                      <table style="width: 100%;">
                        <!-- colgroup -->
                        <colgroup>
                          <col style="width: 50%;">
                          <col style="width: 25%;">
                          <col style="width: 25%;">
                        </colgroup>

                        <tbody>
                          <tr>
                            <td>
                              <p class="mb-0 font-weight-bolder">
                                {{ $t('totalProductprice') }}
                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success">
                                ¥ {{ Commas(item.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) ) }}
                                <!-- {{ item.price *ele.quantity }} -->
                                <!-- ¥ {{ Commas(item.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) ) }} -->
                                <!-- ¥ {{ Commas(item.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) ) }} -->
                                <!-- ฿ 0.00 -->
                              </p>
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">
                                ฿ {{ Commas((item.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) *
                                  items.rate_yuan) ) }}
                                <!-- ฿ {{ Commas((item.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) * items.rate_yuan) ) }} -->
                                <!-- ฿ 0.00 -->
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p class="mb-0 font-weight-bolder">
                                {{ $t('shippingInchina') }}

                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success">
                                <!-- ¥ {{ Commas(item.shipping_cnfee ? item.shipping_cnfee.toFixed(2): '0.00') }} -->
                                ¥ {{ Commas(item.shipping_cnfee ? item.shipping_cnfee.toFixed(2): '0.00') }}
                              </p>
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">
                                <!-- ฿ {{ item.shipping_cnfee ? Commas(item.shipping_cnfee * details.rate_yuan) : '0.00' }} -->
                                ฿ {{ item.shipping_cnfee ? Commas((item.shipping_cnfee * items.rate_yuan).toFixed(2)) :
                                  '0.00' }}
                                <!-- ฿ {{ Commas(item.shipping_cnfee * items.rate_yuan) }} -->
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p class="mb-0">
                                {{ $t('woodenCrates') }} (฿)
                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success" />
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">
                                ฿ {{ Commas(item.postnumber.reduce((acc, ele) => (acc + (ele.packaging_fee)).toFixed(2),
                                                                   0)) }}
                                <!-- ฿ {{ item.packaging_fee.toFixed(2) }} -->
                                <!-- ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.packaging_fee), 0)) }} -->
                                <!-- ฿ 0.00 -->
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p class="mb-0">
                                {{ $t('ProductInspectionfee') }} (฿)
                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success" />
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">
                                ฿ {{ Commas(item.postnumber.reduce((acc, ele) => (acc +
                                  (ele.product_inspection_fee)).toFixed(2), 0)) }}
                                <!-- ฿ {{ item.product_inspection_fee.toFixed(2) }} -->
                                <!-- ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.product_inspection_fee), 0)) }} -->
                                <!-- ฿ 0.00 -->
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p class="mb-0">
                                {{ $t('newWrappingcost') }} (฿)
                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success" />
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">
                                ฿ {{ Commas(item.postnumber.reduce((acc, ele) => (acc + (ele.re_wrap_fee)).toFixed(2),
                                                                   0)) }}
                                <!-- ฿ {{ item.re_wrap_fee.toFixed(2) }} -->
                                <!-- ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.re_wrap_fee), 0)) }} -->
                                <!-- ฿ 0.00 -->
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p class="mb-0">
                                {{ $t('cashOndelivery') }} (฿)
                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success" />
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">

                                <!-- ฿ {{ Commas(item.cashOndelivery_fee ? item.cashOndelivery_fee.toFixed(2): '0.00') }} -->
                                ฿ {{ Commas(item.cashOndelivery_fee ? item.cashOndelivery_fee.toFixed(2): '0.00') }}
                                <!-- ฿ 0.00 -->
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="mb-0 font-weight-bolder">
                                {{ $t('totalPrices') }}
                              </p>
                            </td>
                            <td>
                              <p class="mb-0 font-weight-bolder text-success" />
                            </td>
                            <td class="text-right">
                              <p class="mb-0 font-weight-bolder text-primary">
                                ฿ {{ Commas((item.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) *
                                  items.rate_yuan) + item.postnumber.reduce((acc, ele) => acc + (ele.packaging_fee), 0)+
                                  item.postnumber.reduce((acc, ele) => acc + (ele.product_inspection_fee),
                                                         0)+item.postnumber.reduce((acc, ele) => acc + (ele.re_wrap_fee),
                                                                                   0)+(Number(item.cashOndelivery_fee) + (Number(item.shipping_cnfee)* items.rate_yuan) ))
                                }}
                                <!-- ฿ {{ Commas((item.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) * details.rate_yuan) + item.postnumber.reduce((acc, ele) => acc + (ele.packaging_fee), 0)+ item.postnumber.reduce((acc, ele) => acc + (ele.product_inspection_fee), 0)+item.postnumber.reduce((acc, ele) => acc + (ele.re_wrap_fee), 0)+(Number(item.cashOndelivery_fee) + (Number(item.shipping_cnfee)* details.rate_yuan) )) }} -->
                                <!-- ฿ 0.00 -->
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <table style="width: 100%;">

                      <thead>
                        <tr>
                          <th class="p-1">
                            {{ $t('parcelCode') }}
                          </th>

                          <th class="text-center">
                            {{ $t('productLot') }}
                          </th>

                          <th class="text-center">
                            {{ $t('arrivedChinaWarehouse') }}
                          </th>

                          <th class="text-center">
                            {{ $t('leftChinaWarehouse') }}
                          </th>

                          <th class="text-center">
                            {{ $t('arrivedThaiWarehouse') }}
                          </th>

                          <th class="text-center">
                            {{ $t('dimensionsCm') }}
                          </th>

                          <th class="text-center">
                            {{ $t('weightKg') }}
                          </th>

                          <th class="text-center">
                            {{ $t('amount') }}
                          </th>

                          <th class="text-center">
                            {{ $t('calculateByKilo') }}(฿)
                          </th>

                          <th class="text-center">
                            {{ $t('queue') }}(฿)
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(itpost,indexP) in item.postnumber"
                        :key="indexP"
                      >
                        <tr>
                          <td>
                            <router-link :to="{ name: 'admin-tracking-edit', params: { id: itpost._id }}">
                              <!-- {{ itpost }} -->
                              {{ itpost.postnumber }}
                              <!-- {{ item.postnumber }} -->
                            </router-link>

                          </td>
                          <td class="text-center">
                            <router-link :to="{ name: 'admin-warehouse-edit', params: { id: itpost.lot_name }}">
                              {{ itpost.lot_name ? `${itpost.lot_name}/${itpost.lot_order}` : '-' }}
                            </router-link>
                          </td>
                          <td class="text-center">
                            {{ itpost.come_chinesewarehouse ? itpost.come_chinesewarehouse : '-' }}
                            <!-- - -->
                          </td>
                          <td class="text-center">
                            {{ itpost.out_chinesewarehouse ? itpost.out_chinesewarehouse : '-' }}
                            <!-- - -->
                          </td>
                          <td class="text-center">
                            {{ itpost.come_thaiwarehouse ? itpost.come_thaiwarehouse : '-' }}
                            <!-- - -->
                          </td>
                          <td class="text-center">
                            {{ itpost.width && itpost.long && itpost.height ? itpost.width + 'x' + itpost.long + 'x' +
                              itpost.height : '-' }}
                            <!-- - -->
                          </td>
                          <td class="text-center">
                            {{ itpost.weight ? Commas(itpost.weight) : '-' }}
                            <!-- - -->
                          </td>
                          <td class="text-center">
                            {{ itpost.charge_cue ? Commas(itpost.charge_cue) : '-' }}
                            <!-- - -->
                          </td>
                          <td class="text-center">
                            {{ itpost.charge_per_kilo ? Commas(itpost.charge_per_kilo) : '-' }}
                            <!-- - -->
                          </td>
                          <td class="text-center">
                            {{ itpost.charge_per_cue ? Commas(itpost.charge_per_cue) : '-' }}
                            <!-- - -->
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="mt-2 d-flex align-items-center">
                      <p
                        class="mb-0 font-weight-bolder"
                        style="color: red;"
                      >
                        {{ $t('remarks') }} : {{ items.shops[index].remark_shops ? items.shops[index].remark_shops:
                          'ไม่มี' }}
                        <!-- หมายเหตุ : ไม่มี -->
                      </p>

                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="row mt-2">
              <div class="col-md-6" />
              <div class="col-md-6">
                <div class="card-info">
                  <h4 class="font-weight-bolder">
                    {{ $t('summaryListofexpenses') }}
                  </h4>
                  <hr>

                  <div>
                    <h4 class="font-weight-bolder">
                      {{ $t('specialServicecosts') }}
                    </h4>

                    <div class="d-flex justify-content-between pl-2 pr-2">
                      <p class="mb-0">
                        {{ $t('shippingInchina') }} ({{ $t('baht') }})
                      </p>

                      <p class="font-weight-bolder">
                        ฿ {{ Commas(shipping_cnfee = items.shops.reduce((acc, shop) => acc + shop.shipping_cnfee, 0) *
                          items.rate_yuan ) }}
                        <!-- - -->
                      </p>
                    </div>

                    <div class="d-flex justify-content-between pl-2 pr-2">
                      <p class="mb-0">
                        {{ $t('woodenCrates') }} ({{ $t('baht') }})
                      </p>

                      <p class="font-weight-bolder">
                        ฿ {{ Commas(packaging_fee = items.shops.map(shop => shop.postnumber.reduce((acc, shop) => acc +
                          shop.packaging_fee, 0)).reduce((re, res) => re + res,0) ) }}
                        <!-- - -->
                      </p>
                    </div>

                    <div class="d-flex justify-content-between pl-2 pr-2">
                      <p class="mb-0">
                        {{ $t('ProductInspectionfee') }} ({{ $t('baht') }})
                      </p>

                      <p class="font-weight-bolder">
                        ฿ {{ Commas(product_inspection_fee = items.shops.map(shop => shop.postnumber.reduce((acc, shop)
                        => acc + shop.product_inspection_fee, 0)).reduce((re, res) => re + res,0) ) }}
                        <!-- - -->
                      </p>
                    </div>

                    <div class="d-flex justify-content-between pl-2 pr-2">
                      <p class="mb-0">
                        {{ $t('newWrappingcost') }} ({{ $t('baht') }})
                      </p>

                      <p class="font-weight-bolder">
                        ฿ {{ Commas(re_wrap_fee = items.shops.map(shop => shop.postnumber.reduce((acc, shop) => acc +
                          shop.re_wrap_fee, 0)).reduce((re, res) => re + res,0) ) }}
                        <!-- - -->
                      </p>
                    </div>

                    <div class="d-flex justify-content-between pl-2 pr-2">
                      <p class="mb-0">
                        {{ $t('cashOndelivery') }} ({{ $t('baht') }})
                      </p>

                      <p class="font-weight-bolder">
                        ฿ {{ Commas(cashOndelivery_fee = items.shops.reduce((acc, shop) => acc +
                          shop.cashOndelivery_fee, 0) ) }}
                        <!-- - -->
                      </p>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between">
                    <p class="mb-0">
                      {{ $t('totalProductprice') }} ({{ $t('baht') }})
                    </p>

                    <p class="font-weight-bolder">
                      ฿ {{ Commas(totalall=items.shops.reduce((acc, shop) => acc + (shop.products.reduce((bf, ele) => bf
                        + (ele.price * ele.quantity), 0)), 0) * items.rate_yuan ) }}
                      <!-- - -->
                    </p>
                  </div>

                  <p class="mb-0">
                    {{ $t('useDiscountCoupon') }} : {{ $t('none') }}
                  </p>

                  <hr>

                  <div class="d-flex justify-content-between">
                    <h3 class="font-weight-bolder">
                      {{ $t('amountDue') }}
                    </h3>

                    <h3 class="font-weight-bolder text-primary">
                      {{ Commas( (shipping_cnfee)+(packaging_fee)+(product_inspection_fee)+(re_wrap_fee)+
                        (cashOndelivery_fee)+(totalall)) }} บาท
                      <!-- - -->
                    </h3>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  // BTable,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    // ValidationProvider,
    ValidationObserver,
    // BFormInput,
    BFormGroup,
    // BFormTextarea,
    // BTable,
    BFormCheckbox,
    // router,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      name: '',
      detail: '',
      set_default: 0,
      show: 0,
      fieldsCar: [
        {
          key: 'car', label: 'รถ', thStyle: { width: '10%', align: 'center' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'rateA', label: 'A', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateB', label: 'B', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateC', label: 'C', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateD', label: 'D', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateD1', label: 'D1', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateS', label: 'S', thStyle: { width: '15%' }, thClass: 'text-center',
        },
      ],
      fieldsShip: [
        {
          key: 'ship', label: 'เรือ', thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'rateA', label: 'A', thStyle: { width: '30%' }, thClass: 'text-center',
        },
        {
          key: 'rateB', label: 'B', thStyle: { width: '30%' }, thClass: 'text-center',
        },
        {
          key: 'rateC', label: 'C', thStyle: { width: '30%' }, thClass: 'text-center',
        },
      ],
      itemsCar: [
        {
          car: 'กิโล.', rateA: '50', rateB: '70', rateC: '15', rateD: '20', rateD1: '200', rateS: '90',
        },
        {
          car: 'คิว.', rateA: '1000', rateB: '1500', rateC: '2000', rateD: '2500', rateD1: '3000', rateS: '3500',
        },

      ],
      itemsShip: [
        {
          ship: 'กิโล.', rateA: '50', rateB: '70', rateC: '15',
        },
        {
          ship: 'คิว.', rateA: '5500', rateB: '9000', rateC: '19000',
        },
      ],
      ID: this.$route.params.id,
      ship: [],
      car: [],
      arr: [],
      Obj: [{
        ID: null,
        name: null,
        detail: null,
        set_default: null,
        show: null,
      }],
      products: [
        {
          color_img: 'https://cbu01.alicdn.com/img/ibank/O1CN01iuYOEV1dXiY9zgJhz_!!2208888423746-0-cib.jpg',
          name: 'เสื้อผ้า',
          color_title: 'สีขาว',
          size_title: 'S',
          url: 'https://portal.p2cargo.com',
        },
      ],
      items: [],
      Postnumber: [],
      PostNumberArr: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getData()
    // this.getPostnumber()
  },
  methods: {
    async getData() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('/order/OrderOne', { params })
        this.items = res
        // console.log(this.items)
        // this.arr = res.product_type
        // this.car = this.arr.filter(ele => ele.product_type_class === 1)
        // this.ship = this.arr.filter(ele => ele.product_type_class === 2)
        // eslint-disable-next-line
        for (const index in this.items.shops) {
          this.items.shops[index].postnumber = []
          this.CalPostNumber(this.items.shops[index], index)
        }
      } catch (e) {
        console.log(e)
      }
    },
    backorderprint() {
      this.$router.push({ name: 'admin-order-print' })
    },
    CalPostNumber(item, ShopIndex) {
      const FindShop = this.PostNumberArr.find(ele => ele.ShopIndex === ShopIndex)
      if (FindShop) {
        this.items.shops[ShopIndex].postnumber = FindShop.item
      } else {
        this.GetPostNumber(item, ShopIndex)
      }
    },
    async GetPostNumber(item, ShopIndex) {
      try {
        const params = {
          // eslint-disable-next-line no-underscore-dangle
          shop_id: item._id,
        }
        const { data: res } = await this.$http.get('/postnumber/byshop', { params })
        if (res.success) {
          this.items.shops[ShopIndex].postnumber = res.data
          this.items = { ...this.items }
          const obj = { ShopIndex, item: res.data }
          this.PostNumberArr.push(obj)
        }
      } catch (error) {
        console.log(error)
      }
      return []
    },
    printpdf() {
      try {
        window.open(`https://api.p2cargo.com/api/print/orderdatail/${this.ID}`)
      } catch (err) {
        console.log(err)
      }
    },
    saveprint(data) {
      this.$bvModal.msgBoxConfirm(`${data ? 'บันทึกพิมพ์แล้ว ?' : 'บันทึกยังไม่พิมพ์ ?'}`, {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'warning',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: this.items._id,
            prints: data,
          }
          this.$http.post('/order/updateprint', obj)
            .then(() => {
              this.getData()
              this.Success(`ORD-${this.items.orderId_text}บันทึกข้อมูลเรียบร้อย`)
            })
          // console.log(obj)
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    addRold() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const totalarr = []
          this.arr.forEach(ele => {
            const obj = {
              product_type_id: ele.product_type_id,
              product_type_name: ele.product_type_name,
              product_type_detail: ele.product_type_detail,
              product_type_class: ele.product_type_class,
              product_type_kilo: ele.product_type_kilo,
              product_type_queue: ele.product_type_queue,
            }
            totalarr.push(obj)
          })
          const Obj = {
            ID: this.ID,
            name: this.Obj.name,
            detail: this.Obj.detail,
            set_default: this.Obj.set_default,
            show: this.Obj.show,
            product_type: totalarr,
          }
          this.$http
            .post('/groupbyuser/list/storeAndupdate', Obj)
            .then(() => {
              this.Alert(true, 'เพิ่มข้อมูลสำเร็จ', '')
              this.$router.push({ name: 'admin-user-type' })
            })
            .catch(error => {
              this.Alert(false, 'เพิ่มข้อมูลไม่สำเร็จ', '')
              console.log(error)
            })
        }
      })
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
